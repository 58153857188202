<template lang="pug">
div
  Breadcrumbs(:breadcrumbs="breadcrumbs")
  router-view
</template>

<script>
import { getDirectoryObject } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'BackOfficeCrewing',
  components: {
    Breadcrumbs: () => import('@/components/atoms/Breadcrumbs')
  },
  data () {
    return {
      breadcrumbs: {
        historyBreadcrumbs: [],
        checkAccess: () => checkAccess('backOffice') || checkAccess('groupHead')
      },
      getDirectoryObject,
      checkAccess
    }
  },
  watch: {
    $route () {
      this.setHistoryBreadcrumbs()
    },
    crewingGroups (val) {
      if (val.length) this.setHistoryBreadcrumbs()
    },
    crewingCompanies (val) {
      if (val.length) this.setHistoryBreadcrumbs()
    }
  },
  computed: {
    ...mapGetters(['crewingCompanyByID']),
    ...mapState({
      crewingGroups: state => state.directory.crewingGroups,
      crewingCompanies: state => state.crewing.crewingCompanies
    })
  },
  beforeMount () {
    if (!checkAccess('backOffice')) {
      if (checkAccess('crewingHead')) this.$router.push({ name: 'crewing-managers-backoffice' })
    }
  },
  methods: {
    setHistoryBreadcrumbs () {
      const routerLink = this.$route.name
      const history = []
      if (routerLink === 'crewing-companies-backoffice' || routerLink === 'crewing-managers-backoffice') {
        history.push({
          text: this.$i18n.t('group'),
          link: { name: 'crewing-groups-backoffice' },
          disabled: routerLink === 'crewing-groups-backoffice'
        })
      }
      if (routerLink === 'crewing-companies-backoffice' || routerLink === 'crewing-managers-backoffice') {
        history.push({
          text: getDirectoryObject({ id: parseInt(this.$route.params.groupID), value: 'crewingGroups' }).name,
          link: { name: 'crewing-companies-backoffice', params: { groupID: this.$route.params.groupID } },
          disabled: routerLink === 'crewing-companies-backoffice'
        })
      }
      if (routerLink === 'crewing-managers-backoffice') {
        history.push({
          text: this.crewingCompanyByID(Number(this.$route.params.companyID)).name_ukr,
          link: { name: 'crewing-managers-backoffice', params: { groupID: this.$route.params.groupID, companyID: this.$route.params.companyID } },
          disabled: routerLink === 'crewing-managers-backoffice'
        })
      }
      this.breadcrumbs.historyBreadcrumbs = history
    }
  }
}
</script>
